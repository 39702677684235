import request from '@/utils/request'

// 查询学校列表
export function listClient (query) {
  return request({
    url: '/rongyi/client/list',
    method: 'get',
    params: query
  })
}

// 查询学校详细
export function getClient (clientId) {
  return request({
    url: '/rongyi/client/' + clientId,
    method: 'get'
  })
}

// 新增学校
export function addClient (data) {console.log("post data");console.log(data);
  return request({
    url: '/rongyi/client/',
    method: 'post',
    data: data
  })
}

// 修改学校
export function updateClient (data) {
  return request({
    url: '/rongyi/client/',
    method: 'put',
    data: data
  })
}

// 删除学校
export function delClient (clientId) {
  return request({
    url: '/rongyi/client/' + clientId,
    method: 'delete'
  })
}
